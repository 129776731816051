import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { findLast } from 'lodash-es';
import { filter } from 'rxjs/operators';

const DEFAULT_URL = '/dashboard';
const INITIAL_HISTORY_LENGTH = 2;

@Injectable()
export class RoutingStateService {
    private _history: string[] = [];

    constructor(private router: Router) {
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
                this._history = [...this._history, urlAfterRedirects];
            });
    }

    get history(): string[] {
        return this._history;
    }

    get previousUrl(): string {
        return this.history[this.history.length - INITIAL_HISTORY_LENGTH] || DEFAULT_URL;
    }

    get currentUrl(): string {
        return this.history[this.history.length - 1] || DEFAULT_URL;
    }

    get landedOnPage(): boolean {
        return this.history.length <= INITIAL_HISTORY_LENGTH;
    }

    urlFromHistory(step: number): string {
        return this.history[this.history.length - INITIAL_HISTORY_LENGTH - Math.abs(step)] || DEFAULT_URL;
    }

    firstPreviousUrlNotMatching(urlMatcher: RegExp): string {
        return (
            findLast(
                this.history,
                (url) => !(url === this.currentUrl || urlMatcher.test(url)),
                this.history.length - INITIAL_HISTORY_LENGTH
            ) || DEFAULT_URL
        );
    }
}
